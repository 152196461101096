import { useAuth0 } from '@auth0/auth0-react';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { FC } from 'react';
import { getUserHash } from '../../utils/auth';
import { IAssessmentProps } from './data';
import { setUserHashInAssessmentDescription } from './utils';

export default function AssessmentDescription({
  assessment,
}: IAssessmentProps) {
  const { user } = useAuth0();
  const userHash = getUserHash(user);

  const descriptionWithHash = setUserHashInAssessmentDescription(
    assessment?.brokee_id || 0,
    assessment?.description_long || '',
    userHash
  );

  const DescriptionHTML: FC<{ description: string }> = ({ description }) => {
    return <div dangerouslySetInnerHTML={{ __html: description }} />;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Card
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" component="div">
              Assessment Description
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            pl: '2rem',
            pr: '2rem',
            borderRadius: '4px',
            overflowY: 'auto',
            userSelect: 'none',
            // Allow copying for specific spans, text should by wrapped like this: <code class="allow-user-interaction">allow copy</span>
            '& .allow-user-interaction': {
              userSelect: 'text',
            },
          }}
        >
          <Typography
            variant="body2"
            display="block"
            color="text.primary"
            gutterBottom
            component="div"
            lineHeight={1.7}
            sx={{
              fontSize: '1rem',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            {descriptionWithHash &&
              DescriptionHTML({
                description: descriptionWithHash,
              })}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
