import { useAuth0 } from '@auth0/auth0-react';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useBilling } from '../../../hooks/useBilling';
import { isPersonal } from '../../../utils/auth';
import { Difficulty_levels } from '../TestCataloguePageList/utils';
import { ICourseDetailsProps } from '../interfaces';
import { convertSecondsToMinutes } from '../../../utils/common';
import { EAssessmentType } from '../../../constants/assessment';

const CourseDetails: FC<ICourseDetailsProps> = ({
  average_passed_time,
  passRate,
  name,
  testType,
  techRoles,
  testDifficulty,
  techServices,
  testIssues,
  testResources,
}) => {
  const { user } = useAuth0();
  const testName = name;
  const testNameSpited = name?.split(':')[0];
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedIssue, setSelectedIssue] = useState<string>('');

  const hideSandboxComponents = testType === EAssessmentType.SANDBOX;

  const { canAccessProFeatures } = useBilling();

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleIssueClose = () => {
    setOpenModal(false);
  };
  const handleIssueChange = (issueTitle: string) => {
    if (selectedIssue === issueTitle) {
      setSelectedIssue('');
    } else {
      setSelectedIssue(issueTitle);
    }
  };

  return (
    <Grid item xs={12} lg={3} md={3} sm={3} id={`assessment-info`}>
      <Grid alignItems="center">
        <CardContent>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ mb: 8, fontWeight: 'bold', fontSize: '1.7rem' }}
          >
            {testNameSpited}
          </Typography>
          {!hideSandboxComponents && (
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, fontWeight: 'bold', fontSize: '1rem' }}
              >
                <b>Average Pass Time</b>
              </Typography>
              <Chip
                label={
                  average_passed_time === 0
                    ? 'Not enough passed data'
                    : `${convertSecondsToMinutes(average_passed_time)} minutes`
                }
                color="default"
                variant="filled"
                sx={{ mr: 2, mb: 1 }}
              />
            </Box>
          )}
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, fontWeight: 'bold', fontSize: '1rem' }}
            >
              <b>Roles</b>
            </Typography>
            {techRoles?.map((role, index) => (
              <Chip
                key={index}
                label={role.tech_role?.name}
                color="success"
                variant="filled"
                sx={{ mr: 2, mb: 1 }}
              />
            ))}
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, fontWeight: 'bold', fontSize: '1rem' }}
            >
              <b>Difficulty</b>
            </Typography>
            {!hideSandboxComponents && passRate > 0 && (
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                {`Only ${passRate}% completed the test`}
              </Typography>
            )}
            <Chip
              label={
                testDifficulty
                  ? testDifficulty?.charAt(0).toUpperCase() +
                    testDifficulty?.slice(1)
                  : ''
              }
              variant="filled"
              sx={{
                mb: 2,
                padding: '2px 2px',
                bgcolor:
                  testDifficulty === Difficulty_levels.HARD
                    ? 'error.main'
                    : testDifficulty === Difficulty_levels.EASY
                    ? 'success.main'
                    : 'warning.main',
                fontWeight: 'medium',
                color: 'common.white',
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, fontWeight: 'bold', fontSize: '1.1rem' }}
            >
              <b>Tech Stack</b>
            </Typography>
            {techServices?.map((item, index) => (
              <Chip
                key={index}
                label={
                  item?.tech_service?.name.charAt(0).toUpperCase() +
                  item?.tech_service?.name.slice(1)
                }
                variant="filled"
                color="secondary"
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Box>
          {!hideSandboxComponents &&
            canAccessProFeatures() &&
            !isPersonal(user) && (
              <Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2, fontWeight: 'bold', fontSize: '1rem' }}
                >
                  <b>Problem List</b>
                </Typography>
                <Button
                  id="test-catalog-show-problem-list"
                  variant="outlined"
                  sx={{
                    mb: 2,
                    color: 'primary.main',
                    padding: '8px 20px',
                    borderColor: 'primary.main',
                    ':hover': {
                      backgroundColor: 'primary.main',
                      color: 'white',
                    },
                  }}
                  size="small"
                  onClick={handleClick}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 'medium',
                      color: 'success',
                    }}
                  >
                    Show Problems
                  </Typography>
                </Button>

                <Dialog
                  open={openModal}
                  onClose={handleIssueClose}
                  maxWidth="md"
                  fullWidth
                  scroll="paper"
                  PaperProps={{
                    style: {
                      padding: '16px',
                      maxHeight: '80vh',
                      display: 'flex',
                      flexDirection: 'column',
                    },
                  }}
                >
                  <DialogTitle
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography
                      variant="h5"
                      bgcolor={'grey.200'}
                      p={2}
                      sx={{
                        width: '100%',
                        borderRadius: '5px',
                        textAlign: 'center',
                      }}
                    >
                      {testName} Problems
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        width: '100%',
                        fontSize: '1rem',
                        display: 'block',
                        mt: 1,
                        pl: 1.2,
                        textAlign: 'center',
                      }}
                    >
                      Each candidate is provided with their own unique set of
                      problems for each test
                    </Typography>
                  </DialogTitle>

                  <DialogContent sx={{ overflow: 'auto', position: 'static' }}>
                    {!testIssues?.length ? (
                      <Typography
                        variant="subtitle1"
                        sx={{ p: 2, textAlign: 'center' }}
                      >
                        No Problems Found
                      </Typography>
                    ) : null}
                    {testIssues?.map((issue, index) => (
                      <Accordion
                        key={index}
                        expanded={
                          selectedIssue === issue.title ||
                          selectedIssue === 'All'
                        }
                        onChange={() => handleIssueChange(issue.title ?? 'N/A')}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls={`panel${index + 1}a-content`}
                          id={`panel${index + 1}a-header`}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ userSelect: 'none' }}
                          >
                            {issue.title ?? 'N/A'}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            variant="body2"
                            sx={{ userSelect: 'none' }}
                          >
                            <b>Explanation:</b> {issue.explanation ?? 'N/A'}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </DialogContent>
                </Dialog>
              </Box>
            )}
        </CardContent>
      </Grid>
    </Grid>
  );
};

export default CourseDetails;
